import './index.css';
import 'normalize.css';
import WebFont from 'webfontloader';

const init = async () => {
        await import(/* webpackChunkName: "browserStart" */ './browserStart');
        WebFont.load({
            custom: {
                families: ['Lato:n3,n4,n6', 'sans-serif', 'Material Icons'],
            },
        });
};

init();
